import { gsap, Power4 } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import Splitting from 'splitting';

import headerColorInit from './animation/header-color';

gsap.registerPlugin(ScrollTrigger);

export default function initPageAnimation() {
    // Sticky banner
    const banner = document.querySelector('.banner');

    if (banner) {
        ScrollTrigger.create({
            trigger: '.banner',
            pin: true,
            start: 'top top',
            end: 'bottom top',
            scrub: 1,
            pinSpacing: false,
        });

        gsap.utils.toArray('.intro-text-wrapper').forEach((el) => {
            const spacer = document.createElement('div');
            spacer.style.display = 'none';
            el.parentNode.insertBefore(spacer, el);

            function updateSpacerHeight() {
                const sectionHeight = el.offsetHeight;
                spacer.style.height = `${sectionHeight}px`;
            }

            const observer = new ResizeObserver(updateSpacerHeight);
            observer.observe(el);

            gsap.fromTo(el, {
                x: '101%',
            }, {
                scrollTrigger: {
                    trigger: '.layout-header',
                    start: 'top top',
                    end: 'bottom top',
                    scrub: true,
                    onToggle: (self) => {
                        if (self.isActive) {
                            gsap.set(spacer, { display: 'block' });
                            gsap.set(el, { position: 'fixed' });
                        } else {
                            gsap.set(spacer, { display: 'none' });
                            gsap.set(el, { position: 'relative' });
                        }
                    },
                    onLeave: () => {
                        gsap.set(spacer, { display: 'none' });
                        gsap.set(el, { position: 'relative' });

                        el.querySelectorAll('[data-animation-gradient-text]').forEach((text) => {
                            const event = new Event('onGradientTextAnimationStart');
                            text.dispatchEvent(event);
                        });
                    },
                    onEnterBack: () => {
                        el.querySelectorAll('[data-animation-gradient-text]').forEach((text) => {
                            const event = new Event('onGradientTextAnimationEnd');
                            text.dispatchEvent(event);
                        });
                    },
                },
                x: '0%',
            });
        });
    }

    // Text gradient animation
    gsap.utils.toArray('[data-animation-gradient-text]').forEach((el) => {
        Splitting({ target: el });
        const chars = el.querySelectorAll('[data-char]');
        const animations = [];

        el.addEventListener('onGradientTextAnimationStart', () => {
            chars.forEach((char) => {
                const animation = gsap.fromTo(char, {
                    backgroundImage: 'linear-gradient(140deg, #fff 0%, #fff 0%, transparent 0%)',
                }, {
                    scrollTrigger: {
                        trigger: char,
                        start: 'top 220px',
                        end: 'top 120px',
                        scrub: 1,
                    },
                    backgroundImage: 'linear-gradient(140deg, #fff 0%, #fff 50%, #fff 150%)',
                });
                animations.push(animation);
            });
        });

        el.addEventListener('onGradientTextAnimationEnd', () => {
            animations.forEach((animation) => {
                gsap.to(animation, {
                    progress: 0,
                    duration: 0.2,
                    onComplete: () => {
                        animation.pause().kill();
                    },
                });
            });
        });
    });

    // Jobs gallery
    const horizontalSections = gsap.utils.toArray('[data-animation-horizontal]');

    const getSectionWidth = (section, toStart) => {
        const sectionWidth = section.scrollWidth - window.innerWidth;
        // eslint-disable-next-line no-nested-ternary
        return section.dataset.animationHorizontal === 'to-left' ? (toStart ? -sectionWidth : 0) : (toStart ? 0 : -sectionWidth);
    };

    const mm = gsap.matchMedia();

    horizontalSections.forEach((section) => {
        const sectionWidth = section.scrollWidth - window.innerWidth;

        mm.add('(min-width: 768px)', () => {
            gsap.fromTo(section, {
                x: () => getSectionWidth(section, true),
            }, {
                x: () => getSectionWidth(section, false),
                ease: 'none',
                scrollTrigger: {
                    start: 'top top',
                    end: `+=${sectionWidth}`,
                    pin: section,
                    invalidateOnRefresh: true,
                    scrub: true,
                },
            });
        });
    });

    // Text gradient animation
    gsap.utils.toArray('[data-animation="background-sticky"]').forEach((el) => {
        ScrollTrigger.create({
            trigger: el,
            start: 'top top',
            end: 'bottom bottom',
            pin: el.querySelector('img'),
            pinSpacing: false,
        });
    });

    // Text animation
    gsap.utils.toArray("[data-animation='text']").forEach((el) => {
        gsap.fromTo(el, {
            y: '10%',
        }, {
            scrollTrigger: {
                trigger: el,
                scrub: true,
                start: 'top bottom',
                end: 'bottom top',
                onEnter: () => {
                    gsap.to(el, { duration: 0.1, opacity: '1' });
                },
            },
            y: '-10%',
        });
    });

    // Image slide down
    gsap.utils.toArray("[data-animation='image-slide-down']").forEach((el) => {
        gsap.fromTo(
            el.querySelector('img'),
            {
                clipPath: 'inset(0 0 100% 0)',
            },
            {
                scrollTrigger: {
                    trigger: el,
                    start: 'top 80%',
                    end: 'bottom 80%',
                },
                clipPath: 'inset(0 0 0% 0)',
                duration: 1,
                ease: Power4.easeIn,
            },
        );
    });

    // Job details - parallax background
    gsap.utils.toArray("[data-animation='background-triangle']").forEach((el) => {
        gsap.fromTo(el, {
        }, {
            scrollTrigger: {
                trigger: el,
                scrub: true,
                start: 'top 75%',
                end: 'top top',
            },
            '--bg-offset-top': 'min(35vh, 10vw)',
        });
    });

    // Page - triangle background
    gsap.utils.toArray('.page-bg-triangle .layout-wrapper').forEach((el) => {
        gsap.fromTo(el, {
        }, {
            scrollTrigger: {
                trigger: el,
                scrub: true,
                start: 'top top',
                end: '+=100%',
            },
            '--bg-offset-top': 'min(25vh, 25vw)',
        });
    });
    // Header color animation
    headerColorInit();
}

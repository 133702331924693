export default function () {
    // Set property vh
    const setPropertyVh = () => {
        const vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    window.addEventListener('resize', setPropertyVh);
    setPropertyVh();

    // Set property scrollbarWidth
    const setPropertyScrollbarWidth = () => {
        let scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;

        scrollbarWidth = scrollbarWidth < 0 ? 0 : scrollbarWidth;

        document.documentElement.style.setProperty('--scrollbar-width', `${scrollbarWidth === 16 ? 17 : scrollbarWidth}px`);
    };

    window.addEventListener('resize', setPropertyScrollbarWidth);
    setPropertyScrollbarWidth();
}

import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger.js';

gsap.registerPlugin(ScrollTrigger);

export default function () {
    const sections = gsap.utils.toArray('[data-section-header-color]');
    const headerContainerInside = document.querySelector('.header-container__inside');

    if (!headerContainerInside) return;

    // Duplicate header elements
    const content = headerContainerInside.innerHTML;
    const wrapper = document.createElement('div');
    wrapper.innerHTML = content;
    wrapper.classList.add('header-container__inside-copy');
    headerContainerInside.appendChild(wrapper);

    sections.forEach((section, i) => {
        const currentColor = gsap.getProperty(section, 'data-section-header-color');
        const prevColor = i > 0 ? gsap.getProperty(sections[i - 1], 'data-section-header-color') : 'primary';

        gsap.to('.header-container', {
            scrollTrigger: {
                trigger: section,
                start: () => `top ${headerContainerInside.offsetTop + headerContainerInside.offsetHeight}px`,
                end: () => `top ${headerContainerInside.offsetTop}px`,
                scrub: true,
                onUpdate: (self) => {
                    if (prevColor === 'primary' && currentColor === 'white') {
                        document.documentElement.style.setProperty('--header-clip-top', 1 - self.progress);
                        document.documentElement.style.setProperty('--header-clip-bottom', 1);
                    }

                    if (prevColor === 'white' && currentColor === 'primary') {
                        document.documentElement.style.setProperty('--header-clip-top', 0);
                        document.documentElement.style.setProperty('--header-clip-bottom', 1 - self.progress);
                    }
                },
            },
        });
    });

    window.addEventListener('resize', () => {
        ScrollTrigger.refresh();
    });
}

export default function () {
    const menuTrigger = document.querySelector('[data-menu-trigger]');
    let isOpen;

    menuTrigger.addEventListener('click', (e) => {
        e.preventDefault();
        document.body.classList.toggle('menu-active');
        document.body.classList.toggle('menu-close', !!isOpen);
        isOpen = !isOpen;
    });

    // Close menu when clicked on link inside when the link is for some section on current page
    const headerNavigation = document.querySelector('.header-navigation');

    if (headerNavigation) {
        const menuLinks = headerNavigation.querySelectorAll('a');

        menuLinks.forEach((link) => {
            link.addEventListener('click', () => {
                const target = link.hash.slice(1);

                if (target && document.getElementById(target)) {
                    document.body.classList.remove('menu-active');
                }
            });
        });
    }

    // Close the menu when the escape key is pressed
    document.addEventListener('keydown', (event) => {
        if (event.key === 'Escape' && document.body.classList.contains('menu-active')) {
            document.body.classList.remove('menu-active');
        }
    });
}

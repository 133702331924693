export default function initScrollToSection() {
    document.body.addEventListener('click', (e) => {
        const { target } = e;
        if (target.tagName === 'A' && target.hash && target.hash !== '#') {
            if (window.location.pathname.replace(/^\//, '') === target.pathname.replace(/^\//, '') && window.location.hostname === target.hostname) {
                const targetElement = document.querySelector(target.hash);
                if (!targetElement) return;
                e.preventDefault();

                targetElement.scrollIntoView({
                    behavior: 'smooth',
                });
            }
        }
    });

    const { hash } = window.location;

    if (hash) {
        const targetElement = document.querySelector(hash);

        if (targetElement) {
            window.addEventListener('scroll', preventScroll, { passive: false });

            // eslint-disable-next-line no-inner-declarations
            function preventScroll() {
                window.scrollTo(0, 0);
                window.removeEventListener('scroll', preventScroll);
            }

            window.addEventListener('load', () => {
                targetElement.scrollIntoView({
                    behavior: 'smooth',
                });
            });
        }
    }
}

import { loadForElements } from './helpers/module-loader';
import initSetCssProperty from './helpers/set-css-property';
import initPageAnimation from './components/page-animation';
import initShowPageMenu from './components/menu';
import initScrollToSection from './components/scroll-to-section';

document.addEventListener('DOMContentLoaded', () => {
    document.documentElement.classList.add('js');

    initSetCssProperty();
    initPageAnimation();
    initShowPageMenu();
    initScrollToSection();

    loadForElements(document.querySelectorAll('a[data-lightbox]'), () => import('./helpers/lightbox'));
    loadForElements(document.querySelectorAll('.ce_accordion'), () => import('./helpers/accordion'));
    loadForElements(document.querySelectorAll('pre code'), () => import('./helpers/highlight'));
    loadForElements(document.querySelectorAll('[data-bs-toggle="tab"]'), () => import('./helpers/tabs'));
});
